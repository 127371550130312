<template>
  <v-app-bar
    v-if="$route.path !== '/authenticate'" 
    app
    dark
    color="primary"
    class="elevation-0" 
    permanent  
    clipped-left 
    clipped flat 
  >
  <v-toolbar-title class="justify-start">
    <v-btn text @click="$router.push('/dashboard')" class="justify-start pl-0">
      <v-img max-height="40" max-width="210" :src="logo" contain></v-img>
    </v-btn>
  </v-toolbar-title>
  <v-spacer/>

  <v-btn v-if="user.isAuth" @click="accountDialog = !accountDialog" outlined class="justify-end rounded-pill mr-3">
        {{ `${user.firstName} ${user.lastName}`}}
  </v-btn>
  <HelpButton v-if="!$route.path.includes('survey')" :details="this.$route"/>
  <v-dialog v-model="accountDialog" max-width="800px">
    <AccountDialog @close="accountDialog = false"/>
   </v-dialog>
</v-app-bar>
</template>
<script>
import { userStore } from '@/store/userStore';
import HelpButton from '@/components/HelpButton.vue'
import AccountDialog from '@/dialogs/AccountDialog.vue';

export default {
  name: 'App',
  components:{
      HelpButton,
      AccountDialog
    },
  setup(){
    const user = userStore()
    const logo = require('@/assets/cognadi_logo.png')
    
    return{
      user,
      logo
    }
  },
  data(){
    return{
      accountDialog:false
    }
  }
}
</script>
<style scoped>

</style>