<template>
  <v-card class="cbat-border">
    <v-card-title>Your Account
      <v-spacer/>
      <ButtonComponent v-if="isEditing" class="mr-4" color="accent" @clickedButton="saveUserEdits" text="Save Edits"></ButtonComponent>
      <ButtonComponent :outlined="(isEditing)?false:true" color="secondary" @clickedButton="isEditing = !isEditing" :text="(isEditing)?'Cancel Edits':'Edit'"></ButtonComponent>
      <ButtonComponent class="ml-3" color="accent" @clickedButton="user.logout" outlined text="Log Out"></ButtonComponent>
      <v-btn class="px-0 ml-3" min-width="0px" text @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-subtitle>
      User type: {{ user.userType }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field :disabled="!isEditing" label="First name" outlined v-model="user.firstName" :hide-details="true"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field :disabled="!isEditing" label="Last name" outlined v-model="user.lastName" :hide-details="true"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field :disabled="!isEditing" label="Your email address" outlined v-model="user.email" :hide-details="true"></v-text-field>
        </v-col>
        <Transition name="fade">
        <template v-if="isChangingPassword" >
          <v-col cols="12">
            <v-text-field :append-icon="(showPassword)?'mdi-eye-off-outline':'mdi-eye-outline'" outlined :type="(showPassword)?'text':'password'" @click:append="showPassword = !showPassword" label="New password" v-model="password" :hide-details="false" :rules="[v => (!!v && v.length > 5) || 'Passwords must be at least 5 characters long']"></v-text-field>
            <v-text-field :append-icon="(showPassword)?'mdi-eye-off-outline':'mdi-eye-outline'" outlined :type="(showPassword)?'text':'password'" @click:append="showPassword = !showPassword" label="Confirm New password" v-model="confirmPassword" :hide-details="false" :rules="[v => v === password || 'Passwords do not match']"></v-text-field>
          </v-col>
        </template>
      </Transition>
      </v-row>
    </v-card-text>
    <v-card-actions width="100%" class="justify-space-between px-6 pb-4">
      <ButtonComponent @clickedButton="isChangingPassword = !isChangingPassword" :text="isChangingPassword?'Cancel changing password':'Change password'" color="secondary" class="mr-3" :outlined="!isChangingPassword"></ButtonComponent>
      <ButtonComponent v-if="isChangingPassword" :isDisabled="!password || `${password}`.length < 5 || password !== confirmPassword" color="accent" text="Confirm new password"></ButtonComponent>
    </v-card-actions>
  </v-card>
</template>
<script>
import ButtonComponent from '@/components/Button.vue'
import apiClient from '@/services/'
import { userStore } from '@/store/userStore'
export default {
  name: 'App',
  components:{
    ButtonComponent
  },
  setup(){
    const user = userStore()
    return{
      user
    }
  },
  data(){
    return{
      isEditing:false,
      isChangingPassword:false,
      password:null,
      confirmPassword:null,
      showPassword:false
  }},
  methods:{
    saveUserEdits(){
      const packet = {
        userId: this.user.userId,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email
      }

      apiClient
        .edit('users', packet)
        .then(() => {
          this.isEditing = false
        })
        .catch(() => {
          console.error("Error")
        })
    },
    savePasswordEdit(){
      const packet = {
        userId: this.user.userId,
        password: this.confirmPassword
      }

      apiClient
        .edit('users', packet)
        .then(() => {
          this.isEditing = false
        })
        .catch(() => {
          console.error("Error")
        })
    },
  }
  }
</script>
<style scoped>

</style>