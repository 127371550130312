import {
    defineStore
  } from 'pinia';
  import router from '../router/'
  
  export const userStore = defineStore('UserStore', {
    // State
    state: () => ({
        userId: null,
        name: null,
        firstName:null,
        lastName:null,
        email: null,
        token: null,
        surveyToken:null,
        userType:'User',
        clientId:null,
        isAuth: false,
        surveyComplete:false
    }),
    persist: {
      storage: window.sessionStorage,
    },
    // Actions
    actions: {
      login(userData){
        this.token = userData.token;
        this.name = userData.name;
        this.firstName = userData.firstName;
        this.lastName = userData.lastName;
        this.email = userData.email;
        this.userId = userData.userId;
        this.userType = userData.userType;
        this.clientId = userData.clientId;
        this.isAuth = true;
        router.push('/dashboard')
        location.reload(true)
      },
      logout(){
        this.userId =  null;
        this.name = null;
        this.email = null;
        this.token = null;
        this.userType = 'User';
        this.clientId = null;
        this.isAuth = false;
        router.push('/authenticate')
        location.reload(true)
      },
    },
    // Getters
    getters: {
      getUserId: (state) => state.userId,
      isLoggedIn: (state) => state.isAuth,
      getToken: (state) => state.token,
      getName: (state) => state.name,
      getSurveyComplete: (state) => state.surveyComplete,
    }
  
  })