import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  userStore
} from '../store/userStore.js';

Vue.use(VueRouter)

const pages = [
  'Dashboard', 
  'Projects', 
  'ModuleDetail',
  'NewModule',
  'NewProject',
  'Authenticate',
  'ReportRedirect',
  'Survey', 
  'Users',
  'Clients',
];

const reports = [
  'CBAT',
  'CFIT'
]

const routes = [];

const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;

for (let i = 0; i < pages.length; i++) {
  routes.push(
    {
      path: `/${pages[i].toLowerCase()}`,
      name: `${pages[i]}Page`,
      component: () => import(`../views/${pages[i]}View.vue`),    
      meta: {
        pageTitle: (() => {  
          if (/[A-Z]/.test(pages[i].substring( 1, pages[i].length))){
            let wordArray = pages[i].match(wordRegex)
            return wordArray.join(" ")
          } else {
            return pages[i]
          } 
        })()
      },
    }
  )
}

for (let a = 0; a < reports.length; a++) {
  routes.push(
    {
      path: `/report/${reports[a].toLowerCase()}`,
      name: `${reports[a]}Report`,
      component: () => import(`../views/reports/Report${reports[a]}.vue`)
    }
  )
}


routes.push({path:'/', redirect:'/dashboard'})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router guard

router.beforeEach(async (to, from, next) => {
  const user = userStore()
  
  if (from.name === 'ReportRedirectPage'  && to.query?.token) {
    next(); 
  } else if (['SurveyPage', 'ReportRedirectPage'].includes(to.name) && (to.query?.token || to.query?.view_type === 'preview')){
    /// if user is not logged in, only allow progress to the auth or survey pages or report pages
    next(); 
  } else if (to.query?.module_id === 'undefined' || to.query?.project_id === 'undefined'){
    next ({ name: 'AuthenticatePage' })
  } else if (user.isLoggedIn && user.userType === 'Super Admin'){
    /// super admin, allow to proceed to any page
    next();
  } else if (user.isLoggedIn && user.userType === 'Client Admin' && to.name !== 'ClientsPage'){
    /// client admin, allow to proceed to any page EXCEPT the Manage Clients page
    next();
  } else if (user.isLoggedIn && user.userType === 'User' && !(['ClientsPage', 'UsersPage', 'ProjectsPage']).includes(to.name) ){
    /// basic user, allow to proceed to any page EXCEPT the Clients, Users, and Projects pages
    next();
  } else if (to.name === 'AuthenticatePage') {
    next ();
  } else {
    next ({ name: 'AuthenticatePage' });
  }
})

export default router

