<template>
  <v-btn data-cy="helpButton" fab x-small color="white" class="mr-2" :retain-focus-on-click="false" @click="showHelpDialog(details??null)">
      <v-icon medium color="primary">mdi-help</v-icon>
      <v-dialog v-model="helpDialog" height="100%" :retain-focus="false" persistent hide-overlay :content-class="(moveLeft)?'justify-end helpDialog moveLeft':'justify-end helpDialog'" width="33vw" style="overflow:hidden;" >
        <v-card class="pb-4" >
          <v-card-title>
              Walkthrough guides
              <v-spacer/>

              <v-item-group>
                <v-btn icon color="primary">
                    <v-icon @click="helpDialog = false">mdi-close</v-icon>
                </v-btn>
              </v-item-group>
            </v-card-title>

            <!-- <v-chip-group class="mx-6">
              <v-chip v-for="(embed) in helpDetails" :key="`chip${embed.help_desk_id}`" color="primary" small>{{embed.type}}</v-chip>
            </v-chip-group>                 -->
            <v-card class="pa-0 ma-0" id="scribeEmbed" flat color="transparent" style="overflow-y:scroll; max-height: calc(100vh - 48px - 64px);">

              <iframe src="https://scribehow.com/page-embed/Cognadi__92jRDR22T4WibozOiGTPFg?as=scrollable&removeLogo=true" width="100%" height="640" allowfullscreen frameborder="0"></iframe>
                  </v-card>
                  </v-card>
    </v-dialog>

    </v-btn>

  </template>

<script>
import EventBus  from '@/utils/eventBus/'

export default {
  name:'HelpButton',
  props:{
      details: {type: Object, default: () => {return {}}},
    },
  mounted(){
    EventBus.$on('openHelpDialog', (item) => {
      this.showHelpDialog(item)
    });
  },
  data () {
    return {
      isLoading:true,
      helpDialog: false,
      helpDetails:[],
      searchTerm:null,
      searchWidth:200,
      moveLeft:false
    }
  },
  computed:{
    filteredHelpDetails(){
      if(this.helpDetails.length > 0 && this.searchTerm !== null && this.searchTerm !== ''){
        return this.helpDetails.filter(v => (v.description.toLowerCase()).includes(this.searchTerm.toLowerCase()))
      } else{
        return this.helpDetails
      }
    }
  },
  methods: {
    showHelpDialog(details){
      if (details){
        this.helpDialog = !this.helpDialog
      }
    }
  }
}

</script>

<style>

.v-expansion-panel-content__wrap{
  position:relative;
}

.helpDialog{
  min-width: 690px;
  transition: ease all 250ms;
}

.v-dialog__content:has(.helpDialog){
  align-items: start;
  justify-content: end;
  bottom: 0px;
  top: auto;
}

.v-dialog__content:has(.helpDialog.moveLeft){
  justify-content: start;
}

#scribeEmbed .__next .h-screen {
  display: none;
}



</style>