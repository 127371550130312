import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import '@fortawesome/fontawesome-pro/css/all.css';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    light: true,
    options: { customProperties: true },
    themes:{
      light:{
        primary: '#181818',
        secondary: '#2c7665',
        tertiary: '#f0f1f1',
        accent: '#e98d14',
        error: '#e46565',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        aqua:'#5fc0de',
        background: '#ededed',
        cfit: '#0086A6'
        
      }
    }
  }
});
