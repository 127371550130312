import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {
  createPinia,
  PiniaVuePlugin
} from 'pinia'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
Vue.use(pinia)

Vue.use(VueApexCharts)

Vue.component('apex-chart', VueApexCharts)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  PiniaVuePlugin,
  pinia,
  render: h => h(App)
}).$mount('#app')
