<template>
  <v-app>
    <AppBar v-if="showBar"/>
    <v-main >
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import routes from './router'
import AppBar from './components/AppBar.vue'
export default {
  name: 'App',
  components:{
      AppBar
    },
  data () {
    return {
      showBar:false,
      ignorePaths:[ // use this array to exclude pages from the nav menu
        '/', 
        '/authenticate', 
        '/survey',
        '/cbatreport',
      ], 
    }
  },
  computed:{
    navPages() {
      return routes.options.routes.filter(item => !this.ignorePaths.includes(item.path.toLowerCase()))
    },
    isNavBarShowing(){
      return this.ignorePaths.includes(this.$router.currentRoute.path)      
    }
  },
  methods: {
    navTo(link) {
        this.$router.push(link);
    }
  },
  mounted() {
    this.showBar = true
  }

};
</script>

<style>
@import '@/styles/variables.scss';

</style>
