import axios from 'axios'
import { userStore } from "@/store/userStore";

const apiClient = axios.create({
  baseURL: 'https://cognadi-app-hh3ut.ondigitalocean.app',
  // baseURL: 'http://localhost:3000',
  withCredential: false
})

apiClient.interceptors.request.use(
  async (config) => {
      const user = userStore();

      config.headers = {
        authorization: user.getToken
      };
      return config;
  },
  (error) => {
      Promise.reject(error);
  }
);
 
export default {

  //// Generic calls

  getSingle(table, id) {
    return apiClient.get(`/${table}/single/${id}`)
  },
  
  getById(table, id) {
    return apiClient.get(`/${table}/getById/${id}`)
  },
  
  getAll(table) {
    return apiClient.get(`/${table}/getAll`);
  },

  add(table, packet) {
    return apiClient.post(`/${table}/add`, packet)
  },

  edit(table, packet) {
    return apiClient.post(`/${table}/edit`, packet)
  },

  delete(table, packet) {
    return apiClient.post(`/${table}/delete`, packet)
  },

  //// project

  fetchProject(packet) {
    return apiClient.post(`/projects/fetch`, packet)
  },

  //// Sending emails

  emailSend(type, packet){
    return apiClient.post(`/emails/${type}`, packet)
  },

  //// User Authorization

  login(email, password) {
    return apiClient.post('/auth/userLogin', {
      email: email,
      password: password
    })
  },

  checkToken(token, userId) {
    return apiClient.post(`/auth/checkToken/`, {
      token: token,
      userId: userId
    })
  },

  passwordReset(email) {
    return apiClient.post('/users/password/reset', {
      email: email
    })
  },

  pinReset(pin, password) {
    return apiClient.post('/users/password/pinReset', {
      pin: pin,
      password: password
    })
  },

  checkExistingEmail(email){
    return apiClient.get(`/users/checkExistingEmail/${email}`)
  },

//// project token check for external reports

  checkProjectToken(token, projectId) {
    return apiClient.post(`/projects/checkProjectToken/`, {
      token: token,
      projectId: projectId
    })
  },

  decodeToken(token){
    return apiClient.get(`/auth/decode/${token}`)
  },

  makeToken(item){
    return apiClient.post(`/auth/makeToken/`, {
      item
    })
  }



  
}
