<template>
  <v-hover v-slot="{ hover }">
    <v-btn 
      dark
      :text="textBtn"
      :outlined="outlineRules(hover)"
      :large="(size==='large')?true:false" 
      :small="(size==='small')?true:false" 
      class="elevation-0 Button"
      :disabled="isDisabled" 
      :color="color??'secondary'" 
      @click="clickHandler"
    >
    <v-row class="justify-center align-center">
      <v-col cols="12" class="py-0 text-center">
        <v-icon v-if="icon" size="25">{{icon}}</v-icon>
      </v-col>
      <v-col cols="12" class="py-0 text-center" :style="(icon)?'font-size:0.75rem':undefined">
        {{text}}
      </v-col>
    </v-row>
   
    
    </v-btn>
  </v-hover>
</template>

<script>

export default {
  name: 'ButtonComponent',
  props:['text', 'textBtn', 'isDisabled', 'size', 'color', 'icon', 'noOutline'],
  methods: {
    clickHandler(){
      this.$emit('clickedButton')
    },
    outlineRules(hover){
      let outlined = false
      
      if (this.isDisabled){
        outlined = true
      }

      if (!hover && !this.noOutline){
        outlined = true
      }

      return outlined

    }
  },

};
</script>

<style>

.v-btn--is-elevated {
  border: thin solid transparent;
}
</style>